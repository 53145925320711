<template>
  <Notifications
    position="top right"
    class="mt-4 mr-16 bg-transparent"
    :width="350"
    :close-on-click="true"
    :duration="-1"
    :max="props.max"
    :group="props.group"
  >
    <template v-slot:body="props">
      <div
        class="cursor-pointer notification"
        :class="getClass(props.item.type)"
        @click="props.close"
      >
        <div class="flex-1">
          <span v-if="props.item.text">
            {{ props.item.text }}
          </span>
          <slot></slot>
        </div>
        <div class="">
          <SvgIcon name="close" aria-hidden="true" class="w-6 h-6" />
        </div>
      </div>
    </template>
  </Notifications>
</template>

<script setup lang="ts">
const ERRORTYPE = "error";
const WARNINGTYPE = "warning";

const props = defineProps<{ group?: string, max?: number }>();

const getClass = (type: string) => {
  return type === ERRORTYPE
    ? "is-error"
    : type === WARNINGTYPE
    ? "is-warning"
    : "is-success";
};
</script>
