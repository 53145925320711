import { app } from "./app";

import { createPinia } from "pinia";

import i18n from "./i18n";

import router from "./router/";

import "./directives";

import TpfMultiselect from "@shared/src/components/TpfMultiselect.vue";
import Notifications from "@kyvg/vue3-notification";
import { floatingVueOptions } from "./plugins/floatingVue";
import FloatingVue from "floating-vue";

// Plugins
import "./plugins/vueSafeHtml";

// Styles
import "./assets/css/main.postcss";

// Svg sprite support for IE11
import "svgxuse";

// Global components
import "./globals/svgIcon";
import { loadEnvConfig } from "@core/src/helpers/environment";
import { initializeSentry } from "@shared/src/services/sentry";
import { initializeApi } from "./services/api";

async function initializeApp() {
  const config = await loadEnvConfig(import.meta.env as ImportMetaEnv);
  // @ts-ignore
  window.runtimeEnv = config; // Store config in window object for use outside of vue context
  initializeSentry(app, import.meta.env, router);
  initializeApi(config);

  app.component("TpfMultiselect", TpfMultiselect);
  app.use(FloatingVue, floatingVueOptions);
  app.use(createPinia());
  app.use(i18n);
  app.use(router);
  app.use(Notifications);
  app.provide("config", config);
  app.mount("#app");
}

initializeApp().catch((error) => {
  console.error("Failed to initialize the app:", error);
});
