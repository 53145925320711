import { useRouter, useRoute } from "vue-router";

import type ITenant from "@/interfaces/ITenant";

import {
  setPrimaryColorShades,
  setSecondaryColorShades,
} from "@core/src/services/colorShades";
import { getTenant } from "@/services/tenant";
import i18n from "@/i18n";

import { useAuthStore } from "@/store/Auth";
import { useTenantStore } from "@/store/Tenant";

export function useTenantHelper() {
  const tenantStore = useTenantStore();
  const authStore = useAuthStore();
  const router = useRouter();
  const route = useRoute();

  const handleTenant = async (tenantTag: string) => {
    try {
      const tenant = (await getTenant(tenantTag)) as ITenant;
      document.documentElement.style.setProperty(
        "--color-primary",
        tenant.primaryColor
      );
      document.documentElement.style.setProperty(
        "--color-secondary",
        tenant.secondaryColor
      );
      tenantStore.setTenant(tenant);

      // Set color shades
      await setPrimaryColorShades(tenant.primaryColor);
      await setSecondaryColorShades(tenant.secondaryColor);

      const filterMessages = (lang: string) => {
        const entries: any = {};
        Object.keys(tenant.messages).forEach((key) => {
          if (key.endsWith(lang)) {
            if (tenant.messages[key]) {
              entries[`${key.slice(0, -2)}`] = tenant.messages[key];
            }
          }
        });
        return entries;
      };

      // Check if the current tenant has custom trnaslations
      if (tenant.messages) {
        tenant.availableLanguages.forEach((lang: any) => {
          const code: string = lang.iso6391Code;
          const capitalized = code.split("");

          capitalized[0] = capitalized[0].toUpperCase();

          const customTenantMessages = filterMessages(capitalized.join(""));

          const existingMessages = i18n.global.messages.value;

          i18n.global.setLocaleMessage(code, {
            ...existingMessages[code],
            tenantMessages: {
              ...customTenantMessages,
            },
          });
        });
      }

      // Return if the user is logged in
      if (authStore.token) {
        return;
      }
      // Check if the current language is available in the tenant
      const currentLanguageIsAvailable =
        tenant.availableLanguages.filter(
          (language) => language.iso6391Code === i18n.global.locale.value
        ).length === 1;

      // If the current language is not available navigate to another language
      if (!currentLanguageIsAvailable) {
        // When router is not supplied path or name,
        // it simply tries to update current route with new params or query
        // Almost everything is optional.
        router.push({
          params: { language: tenant.availableLanguages[0].iso6391Code },
          query: route.query,
        });
      }
    } catch (e) {
      // We want to do nothing here because we are just displaying the tpf styling if there was an error
    }
  };

  return { handleTenant };
}
