const floatingVueOptions = {
  defaultPlacement: "bottom",

  // Default events that trigger the tooltip
  defaultTrigger: "hover focus",

  // Default position offset (px)
  defaultOffset: "4",
  defaultPopperOptions: {},

  // Options for popover
  popover: {
    defaultPlacement: "bottom",
    defaultTrigger: "click",
    defaultOffset: 8,
  },
};

export { floatingVueOptions };
