import App from "./App.vue";
import { createApp, h, Fragment } from "vue";
// @ts-ignore
import VueAxe, { VueAxePopup } from "vue-axe";
import vueAxeConfig from "./plugins/vueAxe";

// @ts-ignore
let app: App.App<Element>;

if (import.meta.env.DEV) {
  app = createApp({
    // @ts-ignore
    render: () => h(Fragment, [h(App), h(VueAxePopup)]),
  });
  // @ts-ignore
  app.use(VueAxe, vueAxeConfig);
} else {
  // @ts-ignore
  app = createApp(App);
}

export { app };
