import { tpfApi } from "./api";

/**
 * Gets all tenant information
 *
 * @return The tenant tag
 */
const getTenant = async (tenantTag: string) => {
  const response = await tpfApi.get(`tenant/tenant/${tenantTag}/`);

  const result = response.data;

  return result;
};

export { getTenant };
