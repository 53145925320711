<template v-if="icon">
  <component :is="icon" ref="iconRef" :key="props.name" :class="className" />
</template>

<script setup lang="ts">
import {
  computed,
  defineAsyncComponent,
  type DefineComponent,
  type Ref,
  ref,
  watch,
  shallowRef,
} from "vue";

const props = defineProps({
  /**
   * Required name of the svg file
   */
  name: {
    type: String,
    required: true,
  },
  /**
   * Optional title
   */
  title: {
    type: String,
    default: "",
  },
});

const icon = shallowRef(null);

const className = computed(() => `svg-icon svg-icon--${props.name}`);

const iconRef: Ref<DefineComponent | null> = ref(null);

// Force reactivity to re apply changes on async component when mounting.
watch(
  () => props.name,
  () => {
    icon.value = defineAsyncComponent(
      () => import(`../assets/icons/${props.name}.svg`)
    );
  },
  { immediate: true }
);

// Watch for when icon is loaded.
watch(
  () => iconRef.value,
  (newValue) => {
    if (props.title && newValue?.$el) {
      // Prepend a title tag for accessibility.
      const titleElem = document.createElement("title");
      titleElem.innerText = props.title;
      newValue.$el.prepend(titleElem);
    }
  }
);
</script>
