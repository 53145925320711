// colorService.ts
import tinycolor from "tinycolor2";

type Shade = {
  index: number;
  lighten?: number;
  darken?: number;
};

const shades: Shade[] = [
  { index: 0.5, lighten: 55 },
  { index: 1, lighten: 49 },
  { index: 2, lighten: 35 },
  { index: 3, lighten: 21 },
  { index: 4, lighten: 9 },
  { index: 5, lighten: 1 },
  { index: 6, darken: 7 },
  { index: 7, darken: 13 },
  { index: 8, darken: 16 },
  { index: 9, darken: 22 },
];

/**
 * Sets primary color shades as CSS variables.
 * @param primaryColor - The primary color in hex format.
 */
export async function setPrimaryColorShades(primaryColor: string) {
  shades.forEach((shade) => {
    const color = shade.lighten ? tinycolor(primaryColor).lighten(shade.lighten) : tinycolor(primaryColor).darken(shade.darken)

    if (shade.index < 1) {
      const shadeIndex = 50;
      document.documentElement.style.setProperty(
        `--color-primary-${shadeIndex}`,
        color
      );
    } else {
      document.documentElement.style.setProperty(
        `--color-primary-${shade.index}00`,
        color
      );
    }
  });
}
  
/**
 * Sets secondary color shades as CSS variables.
 * @param secondaryColor - The secondary color in hex format.
 */
export async function setSecondaryColorShades(secondaryColor: string) {
  shades.forEach((shade) => {
    const color = shade.lighten ? tinycolor(secondaryColor).lighten(shade.lighten) : tinycolor(secondaryColor).darken(shade.darken)

    if (shade.index < 1) {
      const shadeIndex = 50;
      document.documentElement.style.setProperty(
        `--color-secondary-${shadeIndex}`,
        color
      );
    } else {
      document.documentElement.style.setProperty(
        `--color-secondary-${shade.index}00`,
        color
      );
    }
  });
}
