import { defineStore } from "pinia";

import type ITenant from "@/interfaces/ITenant";

interface State {
  tenant: ITenant | null;
}

export const useTenantStore = defineStore("tenant", {
  state: (): State => ({
    tenant: null,
  }),

  actions: {
    setTenant(tenant: ITenant) {
      this.tenant = tenant;
    },
  },
  getters: {
    tenantName(state) {
      return state.tenant?.name;
    },

    privacyPolicy(state) {
      return state.tenant?.privacyPolicy;
    },

    legalInformation(state) {
      return state.tenant?.legalInformation;
    },
  },
});
