import { tpfApi } from "./api";

import type IProfile from "@/interfaces/IProfile";
import type IAdditionalFieldAnswer from "@/interfaces/IAdditionalFieldAnswer";
import type IUserAuthResponse from "@/interfaces/IUserAuthResponse";
import { useTenantStore } from "@/store/Tenant";

/**
 * Perform a login
 *
 * @return User Info
 */
const login = async (
  username: string,
  password: string
): Promise<IUserAuthResponse> => {
  const response = await tpfApi.post("account/token/", {
    username,
    password,
  });

  const result = response.data;

  return result;
};

/**
 * Perform a refresh
 *
 * @return User Info
 */
const getRefreshToken = async (token: string): Promise<IUserAuthResponse> => {
  const response = await tpfApi.post("account/token/refresh/", {
    refresh: token,
  });

  const result = response.data;

  return result;
};

/**
 * Destroy the refresh token on logout
 *
 * @return empty
 */
const destroyRefreshToken = async (token: string) => {
  try {
    await tpfApi.post("account/token/destroy/", {
      refresh: token,
    });
    return;
  } catch (error) {
    return;
  }
};

const register = async (
  username: string,
  password: string,
  cohort: number | null,
  gender: number | null,
  language: string,
  assignedCounselor: number | null,
  additionalFieldAnswers: IAdditionalFieldAnswer[] | null
): Promise<IUserAuthResponse> => {
  const tenantStore = useTenantStore();

  const availableLanguages = tenantStore.tenant?.availableLanguages || [];
  const languageMapping: { [language: string]: number } =
    availableLanguages.reduce(
      (acc: { [language: string]: number }, language) => {
        acc[language.iso6391Code] = language.id;
        return acc;
      },
      {}
    );

  const data: {
    username: string;
    password: string;
    cohort: number | null;
    gender: number | null;
    language: number;
    assignedCounselor: number | null;
    additionalFieldAnswers: IAdditionalFieldAnswer[] | null;
  } = {
    username,
    password,
    cohort,
    gender,
    language: languageMapping[language],
    assignedCounselor,
    additionalFieldAnswers,
  };

  const response = await tpfApi.post("account/client-registration/", data);
  const result = response.data;

  return result;
};

const activateUser = async (
  encryptedId: string | null,
  username: string,
  password: string,
  activationCode: number | null,
  language: string
): Promise<IUserAuthResponse> => {
  const response = await tpfApi.post("account/activate-user/", {
    encryptedId,
    username,
    password,
    activationCode,
    language,
  });
  return response.data;
};

const checkResetToken = async (token: string, email: string) => {
  const response = await tpfApi.post(`account/reset-password/check-token/`, {
    token,
    email,
  });

  return response.data;
};

const resetPassword = async (
  password: string,
  token: string,
  email: string
) => {
  try {
    const response = await tpfApi.post(`account/reset-password/reset/`, {
      password,
      token,
      email,
    });

    return response.data;
  } catch (error: any) {
    // TODO
    if (error.response.status == 400) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
};

const forgotPassword = async (email: string) => {
  const response = await tpfApi.post(`account/reset-password/email/`, {
    email,
  });

  return response.data;
};

const getProfile = async (id: number) => {
  const response = await tpfApi.get(`account/client-profile/${id}/`);

  return response.data;
};

const saveProfile = async (id: number, profile: IProfile) => {
  const response = await tpfApi.put(`account/client-profile/${id}/`, profile);

  return response.data;
};

const partialSaveProfile = async (id: number, profile: Partial<IProfile>) => {
  const response = await tpfApi.patch(`account/client-profile/${id}/`, profile);

  return response.data;
};

const getGenders = async () => {
  const response = await tpfApi.get(`account/gender/`);

  return response.data;
};

const changePassword = async (
  userId: number,
  oldPassword: string,
  newPassword: string
) => {
  const response = await tpfApi.put(`account/change-password/${userId}/`, {
    oldPassword,
    newPassword,
  });

  return response.data;
};

const deleteProfile = async (userId: number, password: string) => {
  const response = await tpfApi.delete(`account/client-profile/${userId}/`, {
    data: { password },
  });

  return response.data;
};

export {
  login,
  getRefreshToken,
  destroyRefreshToken,
  register,
  activateUser,
  checkResetToken,
  resetPassword,
  forgotPassword,
  getProfile,
  getGenders,
  saveProfile,
  partialSaveProfile,
  changePassword,
  deleteProfile,
};
