import { app } from "../app";

import VueSafeHTML, { allowedTags } from "vue-safe-html";

app.use(VueSafeHTML, {
  allowedTags: [
    ...allowedTags,
    "p",
    "s",
    "ol",
    "ul",
    "u",
    "li",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "span",
    "a",
  ],
  allowedAttributes: ["href"],
});
