import axios, {
  type AxiosInstance,
  type InternalAxiosRequestConfig,
} from "axios";
import router from "../router";

import { getRefreshToken } from "./auth";

import { useAuthStore } from "@/store/Auth";
import { useTenantStore } from "@/store/Tenant";

import i18n from "@/i18n";

let tpfApi: AxiosInstance;

const loginInterceptor = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const authStore = useAuthStore();

  if (authStore.isLoggedIn) {
    const token = authStore.token;

    // TODO
    // @ts-ignore
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  // @ts-ignore
  config.headers["Accept-Language"] = i18n.global.locale.value;

  return config;
};

const responseFailedInterceptor = (error: any) => {
  // Return any error which is not due to authentication back to the calling service
  if (error.response.status !== 401) {
    return Promise.reject(error);
  }

  const authStore = useAuthStore();

  // Logout user if token refresh didn't work or user is disabled
  if (error.config.url.includes("token/refresh")) {
    // will error
    authStore.removeAuth;
    router.push({ name: "root" });
    return Promise.reject(error);
  }

  if (!authStore.refreshToken) {
    return Promise.reject(error);
  }

  // Try request again with new token
  return getRefreshToken(authStore.refreshToken)
    .then((userData) => {
      // set token in store
      authStore.setUserData(userData);

      // New request with new token
      const config = error.config;
      config.headers["Authorization"] = `Bearer ${userData.access}`;
      return axios.request(config);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const tenantInterceptor = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const tenantStore = useTenantStore();

  const frontendTag = tenantStore.tenant?.frontendTag;

  if (config.method == "post" || config.method === "put") {
    if (!config.data) {
      config.data = {
        tenant: frontendTag,
      };
    } else {
      config.data["tenant"] = frontendTag;
    }
  } else {
    if (!config.params) {
      config.params = {
        tenant: frontendTag,
      };
    } else {
      config.params["tenant"] = frontendTag;
    }
  }

  return config;
};

/**
 * initializeApi - Initializes the Axios API instance with provided configuration.
 *
 * This was added as we meed to pass runtime variables in main.ts to axios. This is the config object-
 *
 * Parameters:
 * - config: ImportMetaEnv | undefined - The configuration object containing environment variables,
 *   used for setting the base URL and other settings in the Axios instance.
 */
export function initializeApi(config: ImportMetaEnv | undefined) {
  tpfApi = axios.create({
    baseURL: config?.VITE_API_URL,
  });

  tpfApi.interceptors.request.use(loginInterceptor);
  tpfApi.interceptors.request.use(tenantInterceptor);
  tpfApi.interceptors.response.use(undefined, responseFailedInterceptor);
}

export { tpfApi };
