import { defineStore } from "pinia";

import type IDialog from "@/interfaces/IDialog";
import type IGoalsDataOption from "@/interfaces/IGoalsDataOption";
import type IDossierGoalsData from "@/interfaces/IDossierGoalsData";
import type IDossier from "@/interfaces/IDossier";
import type IMilestone from "@/interfaces/IMileStone";
import {
  sortActiveMilestones,
  sortInactiveMilestones,
} from "@/helpers/sorting";
import type IMileStone from "@/interfaces/IMileStone";

export interface State {
  dialogs: IDialog[];
  dossier: IDossier | null;
  counselorName: string | null;
  isCounselorStateLabel: boolean;
  goalDataOptions: IGoalsDataOption[] | null;
  goalData: IDossierGoalsData;
  savedGoalData: IDossierGoalsData | null;
}

const goalDataDefaults: IDossierGoalsData = {
  id: null,
  milestones: [],
  reason: "",
  description: "",
  option: null,
  exitDate: null,
};

const milestoneDataDefaults: IMilestone = {
  id: null,
  description: "",
  exitDate: null,
  reward: "",
  isCurrent: true,
  datetime: new Date().toISOString(),
};

export const useConversationStore = defineStore("conversation", {
  state: (): State => ({
    dialogs: [],
    dossier: null,
    counselorName: null,
    isCounselorStateLabel: true,
    goalDataOptions: null,
    goalData: goalDataDefaults,
    savedGoalData: null,
  }),

  getters: {
    currentMilestones() {
      const today = new Date().getTime();
      const active: IMileStone[] = this.goalData.milestones.filter(
        (m: IMileStone) => m.isCurrent
      );

      return sortActiveMilestones(active, today);
    },
    pastMilestones() {
      const today = new Date().getTime();
      const inactive: IMileStone[] = this.goalData.milestones.filter(
        (m: IMileStone) => !m.isCurrent
      );

      return sortInactiveMilestones(inactive, today);
    },
    latestDialog(state) {
      return state.dialogs[state.dialogs.length - 1];
    },
  },

  actions: {
    setDialogs(dialogs: IDialog[]) {
      this.dialogs = dialogs;
    },

    setDossier(dossier: IDossier): void {
      this.dossier = dossier;
    },

    setGoalData(goalData: IDossierGoalsData): void {
      // get option from store
      if (goalData.option) {
        goalData.option =
          this.goalDataOptions?.find(
            (option) => option?.id === goalData.option
          ) || null;
      }
      this.goalData = goalData;

      // Create deep copy of goal data, since goals contains more milestones which are objects itself
      this.savedGoalData = JSON.parse(JSON.stringify(goalData));
    },

    resetGoalData(): void {
      this.goalData = JSON.parse(JSON.stringify(this.savedGoalData));
    },

    setGoalsDataOptions(options: IGoalsDataOption[]): void {
      this.goalDataOptions = options;
    },

    resetState() {
      this.dialogs = [];
      this.counselorName = null;
      this.goalData = goalDataDefaults;
    },

    setCounselorName(name: string) {
      this.counselorName = name;
    },

    setIsCounselorStateLabel(isStateLabel: boolean) {
      this.isCounselorStateLabel = isStateLabel;
    },

    addGoalMilestone(): void {
      const milestone: IMilestone = {
        ...milestoneDataDefaults,
      };
      this.goalData.milestones.push(milestone);
    },
  },
});
