import type { Directive, DirectiveBinding } from "vue";
import { app } from "./app";

import loadingSVG from "@/assets/icons/loading.svg?raw";

const addLoading = (el: any) => {
  el.classList.add("loading-directive__container");

  el.insertAdjacentHTML(
    "afterbegin",
    `<div class="loading-directive"><div><div><svg><use href="${loadingSVG.url}" /></svg></div></div></div>`
  );
};

const removeLoading = (el: any) => {
  el.classList.remove("loading-directive__container");
  el.querySelectorAll(".loading-directive").forEach((value: any) => {
    value.remove();
  });
};

/**
 * Toggle the loader
 *
 * @param {*} el
 * @param {*} binding
 */
const toggleLoading = (el: any, binding: DirectiveBinding<any>) => {
  const value = binding.value;

  if (value) {
    addLoading(el);
  } else {
    removeLoading(el);
  }
};

const loading: Directive<any, any> = {
  beforeMount(el, binding) {
    toggleLoading(el, binding);
  },
  updated(el, binding) {
    toggleLoading(el, binding);
  },
  unmounted(el) {
    removeLoading(el);
  },
};

const focus: Directive<any, any> = {
  mounted: function (el, binding) {
    if (
      !Object.prototype.hasOwnProperty.call(binding, "value") ||
      binding.value
    ) {
      el.focus();
    }
  },
};

const blur: Directive<any, any> = {
  mounted(el) {
    el.addEventListener("click", () => {
      el.blur();
    });
  },
};

app.directive("loading", loading);
app.directive("focus", focus);
app.directive("blur", blur);
