<template>
  <div
    v-if="hasUnreadDialog"
    class="unread-dialog-indicator"
    data-testid="unread-dialog-indicator"
  />
</template>

<script lang="ts" setup>
import { useAuthStore } from "@/store/Auth";
import { computed } from "vue";

const authStore = useAuthStore();

const hasUnreadDialog = computed(() => {
  return authStore.hasUnreadDialog;
});
</script>

<style lang="postcss" scoped>
.unread-dialog-indicator {
  @apply absolute right-1 top-1;
  @apply bg-error-600;
  @apply p-[5px] rounded-full;
}
</style>
