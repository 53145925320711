<template>
  <DesktopMenuItem
    :tooltip="$t('navigation.home')"
    target="home"
    icon="home"
    :label="$t('navigation.home')"
    :is-nav-open="props.isNavOpen"
    :params="routeParams"
    data-testid="nav-home"
  />
  <DesktopMenuItem
    :tooltip="$t('navigation.counseling')"
    target="counseling"
    icon="messages"
    :label="$t('navigation.counseling')"
    :is-nav-open="props.isNavOpen"
    :params="routeParams"
    data-testid="nav-counseling"
  >
    <UnreadDialogIndicator />
  </DesktopMenuItem>
  <DesktopMenuItem
    :tooltip="$t('navigation.goal')"
    target="goals"
    icon="goal"
    :label="$t('navigation.goal')"
    :is-nav-open="props.isNavOpen"
    :params="routeParams"
    data-testid="nav-goal"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import DesktopMenuItem from "@shared/src/components/DesktopMenuItem.vue";
import UnreadDialogIndicator from "@/components/UnreadDialogIndicator.vue";

const { locale } = useI18n();

const props = defineProps<{ isNavOpen: boolean }>();

const routeParams = computed(() => ({ language: locale.value }));
</script>
