const getTenantTag = (tenantFromStore: string | undefined) => {
  const queryString = window.location.search;

  // We are defaulting to stopsmoking
  let tenant = tenantFromStore || "stopsmoking";
  if (queryString) {
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has("tenant")) {
      tenant = urlParams.get("tenant") || "stopsmoking";
    }
  }

  return tenant;
};

export default getTenantTag;
