<template>
  <div
    id="app"
    class="text-sm lg:text-base"
    :class="{ 'blur-sm': !isTenantReady }"
  >
    <DesktopMenu v-if="isLoggedIn" class="fixed top-0 z-40 hidden lg:block">
      <template #default="{ isNavOpen }">
        <DesktopMenuItems :is-nav-open="isNavOpen" />
      </template>
    </DesktopMenu>
    <RouterView />
    <MobileMenu v-if="mobileMenuShown" />
    <Notification />
  </div>
</template>

<script lang="ts" setup>
import { computed, watch, ref, type ComputedRef } from "vue";
import { useRoute } from "vue-router";

import Notification from "@core/src/components/Notification.vue";
import DesktopMenu from "@shared/src/components/DesktopMenu.vue";
import MobileMenu from "@/components/MobileMenu.vue";
import DesktopMenuItems from "@/components/DesktopMenuItems.vue";

import type ITenant from "@core/src/types/ITenant";

import { useTenantStore } from "@/store/Tenant";
import { useAuthStore } from "@/store/Auth";
import { useFavicon } from "@core/src/useFavicon";

const route = useRoute();

const tenantStore = useTenantStore();
const authStore = useAuthStore();

const isLoggedIn = computed(() => {
  return authStore.isLoggedIn;
});

const mobileMenuShown = computed(() => {
  return isLoggedIn.value && route.name !== "profile";
});

// @ts-ignore some weird errors in the cli type checker
const tenant: ComputedRef<ITenant> = computed(() => tenantStore.tenant);

// @ts-ignore some weird errors in the cli type checker
useFavicon(tenant);
const isTenantReady = ref(false);

/**
 * Show the page blurry until the tenant messages are ready
 * this is to avoid the misleading display of the language keys
 */
watch(tenant, (tenant: ITenant) => {
  if (tenant) {
    isTenantReady.value = true;
  }
});
</script>

<style lang="postcss" scoped>
#app {
  @apply relative min-h-screen bg-grayTpf-50;
}
</style>
