import { watch, type ComputedRef } from "vue";
import type ITenant from "./types/ITenant";

function useFavicon(tenant: ComputedRef<ITenant>) {

  watch(tenant, (tenant: ITenant) => {
    if (tenant && tenant.favicon) {
      const favicon = document.getElementById("favicon");
      if (favicon instanceof HTMLLinkElement) {
        favicon.href = tenant.favicon;
      }
    }
  }, { immediate: true});
}

export { useFavicon}
