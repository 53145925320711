// ! duplicate in counselor-frontend!
import type IMileStone from "@/interfaces/IMileStone";

const getDiffFromToday = (date: string | Date, today: any): number => {
  const newDate = new Date(date).getTime();

  const diffFromToday = Math.abs(today - newDate);
  return diffFromToday;
};

export const sortMilestones = (milestones: IMileStone[]) => {
  const today = new Date().getTime();
  const active: IMileStone[] = milestones.filter(
    (m: IMileStone) => m.isCurrent
  );
  const inActive: IMileStone[] = milestones.filter(
    (m: IMileStone) => !m.isCurrent
  );

  sortActiveMilestones(active, today);
  sortInactiveMilestones(inActive, today);
  const merged = [...active, ...inActive];
  return merged;
};

const sortInactiveMilestones = (milestones: IMileStone[], today: any) => {
  return milestones.sort((a: IMileStone, b: IMileStone) => {
    if (!a.exitDate) {
      return 0;
    }

    if (!b.exitDate) {
      return 0;
    }
    const aDiff = getDiffFromToday(a.exitDate, today);
    const bDiff = getDiffFromToday(b.exitDate, today);

    if (aDiff > bDiff) {
      return -1;
    }
    if (aDiff === bDiff) {
      return 0;
    }
    return 1;
  });
};

const sortActiveMilestones = (milestones: IMileStone[], today: any) => {
  return milestones.sort((a: IMileStone, b: IMileStone) => {
    if (!a.exitDate) {
      return 0;
    }

    if (!b.exitDate) {
      return 0;
    }
    const aDiff = getDiffFromToday(a.exitDate, today);
    const bDiff = getDiffFromToday(b.exitDate, today);

    if (aDiff > bDiff) {
      return 1;
    }
    if (aDiff === bDiff) {
      return 0;
    }
    return -1;
  });
};

export { sortActiveMilestones, sortInactiveMilestones };
