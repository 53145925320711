import {
  createRouter,
  createWebHistory,
  type RouteLocationNormalized,
} from "vue-router";

import { getRefreshToken } from "@/services/auth";
import { getTenant } from "@/services/tenant";
import getTenantTag from "@/helpers/tenant";
import i18n from "@/i18n";
import routes, { availableLanguages } from "@/router/routes";

import { useAuthStore } from "@/store/Auth";
import { useTenantStore } from "@/store/Tenant";
import { useTenantHelper } from "@/composables/TenantHelper";

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes,
});

/**
 * Check the permissions of a user and route
 *
 * @param {object} to The route to navigate to
 */
const isPermitted = (to: RouteLocationNormalized) => {
  if (to.meta.requiresNoAuth) {
    return true;
  }

  const authStore = useAuthStore();

  return authStore.isLoggedIn;
};

const restoreSession = async (toRoute: RouteLocationNormalized) => {
  const authStore = useAuthStore();
  const tenantStore = useTenantStore();

  await authStore.restoreSession();

  if (authStore.refreshToken) {
    try {
      const response = await getRefreshToken(authStore.refreshToken);
      authStore.setUserData(response);

      const tenant = await getTenant(response.activeTenant);
      document.documentElement.style.setProperty(
        "--color-primary",
        tenant.primaryColor
      );
      document.documentElement.style.setProperty(
        "--color-secondary",
        tenant.secondaryColor
      );
      tenantStore.setTenant(tenant);

      let routePath = toRoute.path;
      // If no language is set we want to set the users language
      if (!toRoute.params.language) {
        routePath = response.language + toRoute.path;
      }

      return {
        path: routePath,
        query: toRoute.query,
        params: { language: response.language },
      };
    } catch (e) {
      authStore.removeAuth();
      return null;
    }
  }
};

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  const tenantStore = useTenantStore();
  const { handleTenant } = useTenantHelper();

  const lang = Array.isArray(to.params.language)
    ? to.params.language[0]
    : to.params.language;

  if (
    to.params.language !== i18n.global.locale.value &&
    availableLanguages.includes(lang)
  ) {
    i18n.global.locale.value = lang;
  }

  const goToLogin = () => {
    return next({
      name: "login",
      params: { language: i18n.global.locale.value || "de" },
    });
  };

  if (!authStore.token) {
    const route = await restoreSession(to);
    if (route) {
      return next(route);
    }
  }

  if (!isPermitted(to)) {
    return goToLogin();
  }

  // Force a tenant in the query parameters
  if (!to.query.tenant) {
    const newTo = {
      name: to.name || "",
      params: {
        ...to.params,
        language: i18n.global.locale.value || "de",
      },
      query: {
        ...to.query,
        tenant:
          tenantStore.tenant?.frontendTag ||
          import.meta.env.VITE_DEFAULT_TENANT,
      },
    };
    return next(newTo);
  }

  const tenant = getTenantTag(tenantStore.tenant?.frontendTag);
  await handleTenant(tenant);

  return next();
});

router.afterEach(async (to) => {
  (window as any)._paq.push(["setCustomUrl", to.path]);
  (window as any)._paq.push(["setDocumentTitle", to.name]);
  (window as any)._paq.push(["trackPageView"]);
});

/**
 * This is a temporary fix to refresh the cache if vite loads dynamic modules,
 * which already have an old version of it stored in the cache.
 * See: https://github.com/vitejs/vite/issues/11804
 **/
router.onError((error: Error, to: RouteLocationNormalized) => {
  if (error.message.includes("Failed to fetch dynamically imported module")) {
    window.location.href = to.fullPath;
    window.location.reload();
  }
});

export default router;
