import { createI18n } from "vue-i18n";

// meh, this isn't really a good type
function loadLocaleMessages(): Record<
  string,
  Record<string, string | Record<string, string>>
> {
  const files = import.meta.globEager("./locales/*.json");

  const messages: Record<string, any> = {};

  Object.keys(files).forEach((file) => {
    const matched = file.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = files[file];
    }
  });

  return messages;
}

const getDefaultLocale = () => {
  const lang = import.meta.env.VITE_I18N_LOCALE || "de";

  return lang;
};

const i18n = createI18n({
  legacy: false,
  fallbackLocale: "de",
  messages: loadLocaleMessages(),
});

export function setLocale(lang?: string) {
  if (!lang) {
    lang = getDefaultLocale();
  }

  i18n.global.locale.value = lang;

  const htmlTag = document.getElementsByTagName("html")[0];
  htmlTag.setAttribute("lang", lang);
}

setLocale(); // initialize the document

export default i18n;
