import { defineStore } from "pinia";
import type IUserAuthResponse from "@/interfaces/IUserAuthResponse";

export interface State {
  token: string | null;
  refreshToken: string | null;
  id: number | null;
  isBlendedCounseling: boolean;
  firstName: string | null;
  lastName: string | null;
  username: string | null;
  dossierId: number | null;
  goal: string | null;
  hasUnreadDialog: boolean | null;
}

export const useAuthStore = defineStore("auth", {
  state: (): State => ({
    token: null,
    refreshToken: null,
    id: null,
    isBlendedCounseling: false,
    firstName: null,
    lastName: null,
    username: null,
    goal: null,
    dossierId: null,
    hasUnreadDialog: false,
  }),

  getters: {
    /**
     * Whether the user is logged in
     * @param {object} state Module state
     */
    isLoggedIn(state): boolean {
      return state.token !== null && state.token !== undefined;
    },
  },
  actions: {
    /**
     * Set the JWT token
     *
     * @param {object} state Vuex state
     * @param {string} token token
     */
    setToken(token: string) {
      this.token = token;
    },
    /**
     * Set the Refresh token
     *
     * @param {object} state Vuex state
     * @param {string} token token
     */
    setRefreshToken(token: string) {
      sessionStorage.setItem("refreshToken", token);
      this.refreshToken = token;
    },

    /**
     * Set the user id
     * @param id User id
     */
    setId(id: number | null) {
      this.id = id;
    },

    setIsBlendedCounceling(value: boolean) {
      this.isBlendedCounseling = value;
    },

    setUnreadDialog(value: boolean) {
      this.hasUnreadDialog = value;
    },

    /**
     * Remove the login information and effectively log the user out
     * @param {object} state Module state
     */
    removeAuth() {
      this.token = null;
      this.refreshToken = null;
      this.id = null;
      this.firstName = null;
      this.lastName = null;
      this.username = null;
      this.goal = null;
      sessionStorage.clear();
    },

    resetState() {
      this.removeAuth();
    },

    async restoreSession() {
      const refreshTokenString = sessionStorage.getItem("refreshToken");
      if (refreshTokenString) {
        this.setRefreshToken(refreshTokenString);
      }
    },

    setUserData(data: IUserAuthResponse) {
      this.token = data.access;
      this.setRefreshToken(data.refresh);
      this.id = data.id;
      this.firstName = data.firstName;
      this.lastName = data.lastName;
      this.username = data.username;
      this.goal = data.goal || "";
      this.dossierId = data.dossierId || 0;
      this.hasUnreadDialog = data.hasUnreadDialog;
    },
  },
});
