<template>
  <RouterLink
    v-tooltip.right="{
      content: props.isNavOpen ? '' : props.tooltip,
      ...popperOptions,
    }"
    :to="{ name: props.target, params: props.params }"
    :exact="props.exact"
    class="relative"
  >
    <SvgIcon
      :name="props.icon"
      :aria-hidden="props.isNavOpen"
      :title="props.label"
    />
    <slot></slot>
    <span v-show="props.isNavOpen" class="overflow-hidden whitespace-nowrap">
      {{ props.label }}
    </span>
  </RouterLink>
</template>

<script setup lang="ts">
import type { RouteParamsRaw } from "vue-router";

const props = withDefaults(
  defineProps<{
    tooltip: string;
    target: string;
    exact?: boolean;
    icon: string;
    label: string;
    isNavOpen: boolean;
    params?: RouteParamsRaw;
  }>(),
  { exact: false, params: undefined },
);

// This one is needed to fix the tooltip position (it seems like the position is buggy if a parent container has overflow-auto)
const popperOptions = {
  preventOverflow: true,
  boundary: "offsetParent",
};
</script>
