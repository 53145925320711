<template>
  <VueMultiselect
    v-bind="$attrs"
    ref="multiselect"
    :placeholder="t('general.pleaseChoose')"
    :class="{ 'multiselect--can-deselect': canDeselect }"
    :close-on-select="!isMultiple"
    open-direction="below"
    :data-testid="props.testId"
    @close="onClose"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <template #caret="{ toggle }">
      <SvgIcon
        name="angle-down-light"
        class="multiselect__select svg-icon--lg !py-0 !px-0 !top-3 !right-3 z-10"
        @mousedown.prevent.stop="toggle"
      />
    </template>

    <template #tag="{ option, remove }">
      <span class="multiselect__tag">
        <span v-text="getOptionLabel(option)"></span>
        <SvgIcon
          name="multiselect-remove"
          tabindex="1"
          class="multiselect__tag-icon"
          @keypress.enter.prevent="removeTag(option, remove)"
          @mousedown.prevent="removeTag(option, remove)"
        />
      </span>
    </template>

    <template #option="{ option }">
      <input
        v-if="isMultiple"
        type="checkbox"
        class="mr-4"
        :checked="isOptionSelected(option)"
      />
      <span>{{ getOptionLabel(option) }}</span>
      <template v-if="!isMultiple">
        <SvgIcon
          name="check"
          class="z-10 hidden svg-icon--lg multiselect__option--check"
        />
        <SvgIcon
          name="close"
          class="z-10 hidden svg-icon--lg multiselect__option--close"
        />
      </template>
    </template>

    <template #afterList>
      <div
        v-if="isMultiple"
        class="sticky bottom-0 flex justify-between pt-3 pb-4 mt-3 bg-white border-t border-grayTpf-200"
      >
        <button class="btn btn--tertiary" @click="cancel">
          {{ $t("general.cancel") }}
        </button>
        <button class="btn btn--primary" @click="confirm">
          {{ $t("general.confirmChoice") }}
        </button>
      </div>
    </template>

    <template #noResult>
      {{ $t("general.noResult") }}
    </template>

    <template #noOptions>
      {{ $t("general.noOptions") }}
    </template>
  </VueMultiselect>
</template>

<script lang="ts" setup>
import {
  useAttrs,
  computed,
  ref,
  watch,
  type Ref,
  type ComponentPublicInstance,
} from "vue";
import { useI18n } from "vue-i18n";
import VueMultiselect from "vue-multiselect";

const { t } = useI18n();
const attrs = useAttrs();

const emit = defineEmits(["blur", "confirm", "update:modelValue"]);

const props = withDefaults(defineProps<{ testId?: string }>(), {
  testId: "multiselect",
});

const multiselect: Ref<(VueMultiselect & ComponentPublicInstance) | null> =
  ref(null);

const noBlurOnClose = ref(false);
const previousValue = ref<any>([]);

const canDeselect = computed(() => {
  return (
    attrs["multiple"] === "" ||
    attrs["multiple"] ||
    attrs["allow-empty"] === "" ||
    attrs["allow-empty"]
  );
});

const isMultiple = computed(() => {
  return attrs["multiple"] || attrs["multiple"] === "";
});

/**
 * Checks for a given option, if it is currently selected.
 */
const isOptionSelected = (option: object): boolean => {
  if (!attrs["modelValue"]) {
    return false;
  }
  const modelValue = attrs["modelValue"] as object[];
  const key = (attrs["track-by"] as string) || "id";
  // true, if any entry in the modelValue has the same id as the given option
  return modelValue.some((value) => {
    return (
      value[key as keyof typeof value] === option[key as keyof typeof option]
    );
  });
};

const getOptionLabel = (option: object): any => {
  const key = (attrs["label"] as string) || "label";
  if (option && key in option) {
    return option[key as keyof typeof option];
  }
  return "";
};

const onClose = () => {
  if (noBlurOnClose.value) {
    noBlurOnClose.value = false;
  } else {
    previousValue.value = attrs["modelValue"] || [];
    emit("blur");
  }
};

const close = () => {
  if (multiselect.value) {
    multiselect.value.deactivate();
  }
};

const open = () => {
  multiselect.value?.activate();
};

/**
 * Confirm and close.
 */
const confirm = () => {
  emit("confirm");
  close();
};

/**
 * Reset value back to previous state and close.
 */
const cancel = () => {
  noBlurOnClose.value = true;
  emit("update:modelValue", previousValue.value);
  close();
};

/**
 * Remove tag and blur if not open.
 */
const removeTag = (option: object, removeCallback: Function) => {
  removeCallback(option);
  if (!checkIsOpen()) {
    emit("blur");
  }
};

/**
 * Returns true if the multiselect is open.
 * Can't be a computed property, since reactivity doesn't trigger correctly.
 */
const checkIsOpen = (): boolean => {
  return multiselect.value?.$el?.classList.contains("multiselect--active");
};

// Save the previous value, in case cancel is clicked.
watch(
  () => attrs["modelValue"],
  () => {
    if (!checkIsOpen()) {
      previousValue.value = attrs["modelValue"] || [];
    }
  },
  { immediate: true, deep: true },
);

defineExpose({ open });
</script>
